import React from 'react';
import '../styles/FeeCard.css';

function FeeCard({ feePrice, text, isSelected, onClick }) {
    return (
        <div 
            className={`fee-card ${isSelected ? 'selected' : ''}`} 
            onClick={onClick}
        >
            <div className="fee-price">{feePrice}</div>
            <div className="fee-text">{text}</div>
        </div>
    );
}

export default FeeCard;
