import AboutUs from "../componets/About";
import AwardsSection from "../componets/Awards";
import ContactSection from "../componets/Contact";
import CourseFees from "../componets/Fees";
import Footer from "../componets/Footer";
import RegistrationGuidelines from "../componets/GuideLines";
import Header from "../componets/Header";
import HeroSection from "../componets/Hero";
import StayInformed from "../componets/Informed";
import RaceDayTimeline from "../componets/RaceDayTimeline";
import RegistrationSection from "../componets/Registration";
import Sponsors from "../componets/Sponsors";
import UseOfFundsSection from "../componets/UseOfFunds";

const LandingPage = () => {
    return ( 
        <div>
            <Header />
            <HeroSection />
            <Sponsors />
            <AboutUs />
            <UseOfFundsSection />
            <RegistrationGuidelines />
            <CourseFees />
            <RegistrationSection />
            <RaceDayTimeline />
            <AwardsSection />
            <StayInformed />
            <ContactSection />
            <Footer />
            {/* <h1>Landing Page</h1> */}
        </div>
     );
}
 
export default LandingPage;