import React from 'react';
import '../styles/Awards.css';

function AwardsSection() {
    return (
        <div className="awards-section">
            <h2 className="awards-title">Race Awards</h2>
            <h3>Celebrate Your Achievement</h3>
            <p>All 5K race awards are recognized at the conclusion of the 5K race. The awards service includes 1st, 2nd, and 3rd place overall medals for male and female finishers. <br/> All Kids’ Fun Run finishers will receive a participation ribbon.</p>
            
        </div>
    );
}

export default AwardsSection;
