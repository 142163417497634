import React, { useState } from 'react';
import '../styles/Fees.css';
import FeeCard from './FeeCard';  // Import the FeeCard component
import { AiFillFilePdf } from 'react-icons/ai';  // Import the PDF icon
import raceForm from '../images/Registration_Waiver_2023_Packet.pdf';  // Import the PDF file

function CourseFees() {
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = (index) => {
        setSelectedCard(index);
    };

    const feeOptions = [
        { feePrice: '$10', text: 'Registration fee for Kids’ Fun Run/Walk (no t-shirt, ribbon for all finishers)' },
        { feePrice: '$20', text: 'Early registration for 5K Run and 5K Walk (Pre-registration due 9/6/23)' },
        { feePrice: '$25', text: 'Registration Fee for 5K Run and 5K Walk, after 9/13/23 (Free T-shirt mailed after race)' },
        { feePrice: '$30', text: 'Late Registration t-shirt will be mailed. On-site registration (no t-shirt provided)' }
    ];

    return (
        <div className="course-fees-section">
            <h2>Choose Your Run</h2>
            <p className="fees-description">
            Explore our various registration options tailored to fit your preferences. Secure your spot early to enjoy special discounts and guaranteed T-shirts. Latecomers are welcome too, but some perks like free T-shirts may not be available. Kids can join the fun at a discounted rate, and they'll all receive a finisher's ribbon!
            </p>
            <div className="fees-list">
                {feeOptions.map((option, index) => (
                    <FeeCard
                        feePrice={option.feePrice}
                        text={option.text}
                        key={index}
                        isSelected={selectedCard === index}
                        onClick={() => handleCardClick(index)}
                    />
                ))}
            </div>
            <div className={`pdf-download ${selectedCard !== null ? 'visible' : ''}`}>
                <AiFillFilePdf size={40} />
                <a href={raceForm} download>
                    <span>Download Registration Form</span>
                </a>
            </div>
        </div>
    );
}

export default CourseFees;