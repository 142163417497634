import React, { useEffect, useState } from 'react';
import '../styles/Hero.css';
import flyer from '../images/5K_flyer.png';
import bgImage1 from '../images/hero_bg_img_1.jpg';  // Import background image
import bgImage2 from '../images/hero_bg_img_2.jpg';
import bgImage3 from '../images/hero_bg_img_3.jpg';
import bgImage4 from '../images/hero_bg_img_4.jpg';
import {Link} from 'react-scroll';

function HeroSection() {
    const [loaded, setLoaded] = useState(false); // Start with false
    const [currentBgIndex, setCurrentBgIndex] = useState(0);

    const bgImages = [bgImage1, bgImage2, bgImage3];

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 100); // Set to true after 100ms
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
        }, 5000); // Change every 5 seconds

        return () => clearInterval(interval);
    }, [bgImages.length]);

    return (
        <div className={`hero-section ${loaded ? 'loaded' : ''}`}>
            <div className="bg-overlay"></div>
            {bgImages.map((img, index) => (
                <div
                    key={index}
                    className={`bg-layer ${index === currentBgIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${img})` }}
                />
            ))}
            <div className="left-column">
                {/* <img src={flyer} alt="5K Run & Walk" className="hero-image" /> */}
            </div>
            <div className="right-column">
                <h2>Join the Piedmont Pan Hellenic Council 5K Run & Walk</h2>
                <span className="race-date">Race Date: September 30, 2023</span> {/* Added race date */}
                <p>Come and join us for a fantastic run & walk event that brings people together. Be part of something great!</p>
                <Link
                    to="registration-guidelines" // Target the id of the RegistrationGuidelines section
                    smooth={true} // Smooth scrolling
                    duration={500} // Duration of the scroll animation (in milliseconds)
                >
                <button className="register-btn">Register</button>
                </Link>

            </div>
        </div>
    );
}

export default HeroSection;
