import React from 'react';
import '../styles/Guidelines.css';

function RegistrationGuidelines() {
    return (
        <div id="registration-guidelines" className="registration-guidelines">
            <h2>Registration Guidelines</h2>
            <div className="guidelines-content">
                <h3>Register Before the Race</h3>
                <ul>
                    <li><strong>Pre-registration Deadline:</strong> September 6th</li>
                    <li><strong>Race Day Registration:</strong> Available for Kids' Fun Run, 5K Run, and 5K Walk.</li>
                    <li><strong>Free Escort:</strong> Participants may have one free escort for Kids’ Fun Run/Walk.</li>
                    <li><strong>Form Submission:</strong> Registration forms can be machine-copied. Mailed forms must be received by September 13, 2023.</li>
                    <li><strong>Entry Rejection:</strong> 5K race officials reserve the right to reject any entry.</li>
                    <li><strong>Refunds:</strong> No refunds are available.</li>
                    <li><strong>Receipt:</strong> Your canceled check serves as your receipt.</li>
                </ul>            
            </div>
        </div>
    );
}

export default RegistrationGuidelines;
