import React from 'react';
import '../styles/Contact.css';

function ContactSection() {
    return (
        <div className="contact-section">
            <h2>Contact Us</h2>
            <h3>Reach Out to Us</h3>
            <p>For any inquiries, please contact PPHC at <a href="tel:434-429-4934">434-429-4934</a> or email <a href="mailto:contact@pphc5k.org">contact@pphc5k.org</a>.</p>
        </div>
    );
}

export default ContactSection;
