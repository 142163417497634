import React from 'react';
import '../styles/Header.css'
import { FaUserAlt } from 'react-icons/fa';
import { TbUser } from 'react-icons/tb';
import { MediaQuery, useMediaQuery } from 'react-responsive';


function Header() {
    // const isMobile = window.innerWidth <= 659; // Determine if the screen width is less than or equal to 767px
    const isMobile = useMediaQuery({ maxWidth: 659 });

    return (
        <div className="header">
            {/* <FaUserAlt className="header-icon" /> */}
            {/* <TbUser className="header-icon" /> */}
            {/* <button className='login-btn'>Login</button> */}
            <h1 className="header-title">{isMobile ? "PPHC 5K":"PPHC FALL 5K RUN & WALK"}</h1>
        </div>
    );
}

export default Header;
