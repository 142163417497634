import React, { useState, useRef  } from 'react';
import '../styles/Splash.css';
import splashImage from '../images/logo-no-bg.png';; // Replace with the path to your image
// import splashImage from '../images/flyer.png';; // Replace with the path to your image

function SplashPage({ onHoverComplete }) {
    const imageRef = useRef(null);
    const [fade, setFade] = useState(false);

    const handleMouseOver = () => {
        const imageWidth = imageRef.current.offsetWidth;
        const windowWidth = window.innerWidth;
        const translateX = `${windowWidth + imageWidth}px`;

        document.documentElement.style.setProperty('--translate-x', translateX);
        setFade(true); // Trigger the fade-out effect

        // Call the provided callback function after the animation duration
        setTimeout(() => {
            onHoverComplete();
        }, 1000); // Match this with the duration of the CSS transition
    };

    return (
        <div className={`splash-page ${fade ? 'fade-out' : ''}`}>
            <img
                ref={imageRef}
                src={splashImage}
                alt="Splash"
                className="splash-image"
                onMouseOver={handleMouseOver}
            />
        </div>
    );
}

export default SplashPage;
