import React from 'react';
import '../styles/Informed.css';

function StayInformed() {
    return (
        <div className="stay-informed-section">
            <h3>Race Information</h3>
            <h4>Stay Informed</h4>
            <p>Remain current with the latest official information on the PPHC Fall Fun Run/Walk & Kids Fun Run by visiting our <a href="https://www.facebook.com/pphc" target="_blank" rel="noopener noreferrer" className="facebook-link">Facebook page</a>.</p>
        </div>
    );
}

export default StayInformed;
