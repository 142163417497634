import React from 'react';
import '../styles/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} <a href='https://www.graytecknologies.com/'>GrayTecknologies</a></p>
        </footer>
    );
}

export default Footer;
