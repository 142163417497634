import React from 'react';
import '../styles/About.css';

function AboutUs() {
    return (
        <div className="about-us-section">
            <h2>About Us</h2>
            <div className="about-us-content">
                <div className="about-us-text">
                    <h3>Introduction to the Piedmont Pan Hellenic Council (PPHC)</h3>
                    <p>The PPHC is an umbrella organization representing black Greek sororities and fraternities in the Danville area, referred to as Divine Nine or D9 organizations. As a governing body, we coordinate activities among members, promote collaboration, and support overall Greek life. Our focus is on facilitating communication, promoting unity, and fostering positive and inclusive community activities.</p>
                </div>
                <div className="about-us-copy">
                    <h3>What We Do</h3>
                    <p>At the Piedmont Pan Hellenic Council (PPHC), we're more than a governing body—we're a community catalyst. Representing Divine Nine black Greek organizations in Danville, our focus spans from facilitating seamless communication and collaboration among members to empowering the community through philanthropic and leadership development initiatives. Committed to upholding core values and fostering inclusivity, we're not just enhancing Greek life but positively impacting the broader Danville community.</p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
