import React from 'react';
import '../styles/UseOfFunds.css';
import flyer from '../images/5K_flyer.png';
import flyer_cropped from '../images/flyer_cropped.png'

function UseOfFundsSection() {
    return (
        <div className="use-of-funds-section">
            <div className="content-section">
                <img className="header-image" src={flyer_cropped} alt="Cropped Flyer" />
                <h3>Use of Funds</h3>
                <h4>Fundraising for the Community</h4>
                <p>The PPHC Fall 5k Fun Run/Walk is more than a race it's a way to give back.</p>
                 <p>As a 501c3 organization, the funds raised from this event will support the community and provide scholarships to area students.</p>
            </div>
            <div className="image-container">
                <img src={flyer} alt="Description" />
            </div>
        </div>
    );
}

export default UseOfFundsSection;
