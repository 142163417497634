import React from 'react';
import '../styles/RaceDayTimeline.css';

function RaceDayTimeline() {
    return (
        <div className="race-day-section">
            <h2 style={{marginBottom:'25px'}}>Race Day Registration & Timeline</h2>
            <div className="race-day-details">
                <h3>Race Day Timeline</h3>
                <ul>
                    <li>Pre-Registration Packet Pickup: 7:30 until start</li>
                    <li>Race Day Registration: 7:30 until start</li>
                    <li>Kids’ Fun Run & Walk: 8:30 a.m. START</li>
                    <li>5K Run & 5K Walk: 9:00 a.m. START</li>
                </ul>
            </div>
        </div>
    );
}

export default RaceDayTimeline;
