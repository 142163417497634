import React from 'react';
import '../styles/Registration.css';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsMailbox } from 'react-icons/bs';

function RegistrationSection() {
    return (
        <div className="registration-section">
            <h2>Completed Registrations</h2>
            <h3>Submit Your Registration</h3>
            <p>Provide completed forms to PPHC, 2023 Race Coordinator mail by September 13, 2023.</p>
            <p> For questions, reach out to the Race Coordinator below.</p>
            <p>Call <a href="tel:434-429-4934">434-429-4934</a> or email <a href="mailto:contact@pphc5k.org">contact@pphc5k.org</a>. Make checks payable to PPHC, Add Fall 5k to Memo Line, and mail by September 13, 2023, to PPHC – Fall 5k Race Registration. Race Coordinator P.O. Box 12046, Danville, VA 24540.</p>
            <p>or Cash App $PPHCGreeks</p>
            <h3>Address, phone, and email for submitting registration forms and checks.</h3>
            <p>Contact information for the Race Coordinator.</p>
            <div className="icon-container">
                <div className="icon-item">
                    <AiOutlinePhone size={40}/>
                    <span>434-434-4934</span>
                </div>
                <div className="icon-item">
                    <AiOutlineMail size={40}/>
                    <span>contact@pphc5k.org</span>
                </div>
                <div className="icon-item">
                    <BsMailbox size={40}/>
                    <span>P.O. Box 12046, Danville, VA 24540</span>
                </div>
            </div>
        </div>
    );
}

export default RegistrationSection;
