import React from 'react';
import logo6 from '../images/gemstone_logo.png';
import logo4 from '../images/gray_teck_logo.png';
import logo3 from '../images/hammer_cpu_logo.png';
import logo2 from '../images/river_city_tax_logo.png';
import logo5 from '../images/trina_logo.png';
import logo1 from '../images/w_w_logo.png';
import '../styles/Sponsors.css';

const Sponsors = () => {
  // Organize logos into four rows
  const logoSponsorRows = [
    [logo1],
    [logo2, logo3],
    [logo4],
    [logo5, logo6],
  ];

   // Text sponsors
   const textSponsors = [
    'Phi Beta Sigma Fraternity, Inc.® - River City Sigmas',
    'Alpha Kappa Alpha Sorority, Inc.® Alpha Phi Omega',
    'Zeta Phi Beta Sorority, Inc.® Eta Eta Zeta Chapter',
    'Danville Chapter of Omega Psi Phi, Fraternity, Inc.®',
    'Danville Alumnae Chapter of \n Delta Sigma Theta Sorority, Incorporated.®',
    'Delta Nu Lambda Chapter of \n Alpha Phi Alpha Fraternity, Inc.®',
    'Andrea Breazeale-King, Zeta Phi Beta Sorority, Inc.®',
    'Danville (VA) Alumni Chapter of Kappa Alpha Psi Fraternity, Inc.®',
  ];

  return (
    <div className="sponsors">
      <h2 style={{ marginBottom: '10px' }}>Our Sponsors</h2>
      <div className="sponsor-grid">
        {logoSponsorRows.map((row, rowIndex) => (
          <div className="sponsor-row" key={rowIndex} id={`row-${rowIndex + 1}`}>
            {row.map((logoUrl, index) => (
              <div className="sponsor" key={index}>
                <img src={logoUrl} alt={`Sponsor ${index + 1}`} id={`sponsor-${index + 1}`} />
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="text-sponsors">
        <h3 style={{ marginTop: '20px' }}>Starting Line Sponsors:</h3>
        <h5>Special thanks to the Danville Running & Fitness club</h5>
        <ul>
          {textSponsors.map((sponsor, index) => (
            <li key={index}>{sponsor}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sponsors;
