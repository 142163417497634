import React, { useEffect, useState } from 'react';
import LandingPage from './pages/LandingPage';
import SplashPage from './pages/SplashPage';
import './App.css';

function App() {
  const [showSplash, setShowSplash] = useState(true);

  const handleHoverComplete = () => {
    document.body.style.overflow = 'auto'; // Re-enable scrolling
    setShowSplash(false);
  };

  useEffect(() => {
    if (showSplash) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  }, [showSplash]);

  return (
    <div className="App">
      {showSplash && <SplashPage onHoverComplete={handleHoverComplete} />}
      {!showSplash && <LandingPage />}
    </div>
  );
}

export default App;
